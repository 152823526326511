<template>
    <ul>
      <component
        :is="resolveNavItemComponent(item)"
        v-for="item in activeUsers"
        :key="item.header || item.title"
        :item="item"
      />

      <!-- {{filteritem}} -->
      <!-- {{items}} -->
      <!-- {{listitem}} -->
      <!-- {{this.items}} -->
    </ul>
    <!-- <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in listuseritem"
      :key="item.header || item.title"
      :item="item"
    />
  </ul> -->
  
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import store from "@/store";
export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent,
    };
  },
  mounted() {
    // this.listitem();
  },
  // methods:{
  //    listitem() {
  //     return this.items.filter(function (u) {
  //       console.log(u.isUser);
  //       if (u.isUser) {
  //         return u;
  //       }
  //     });
  //     console.log("hellow from computed")
  //   },
  // },
  computed: {
    listadminitem: function () {
      return this.items.filter(function (u) {
        if (store.state.auth.role[0] == "admin") {
          if (u.isAdmin) {
            return u;
          }
        } else {
          if (u.isUser) {
            return u;
          }
        }
      });
      console.log("hellow from computed");
    },
     activeUsers: function() {
       return this.items.filter(function(u) {
         console.log(u.isAllowed + "-" + u.title)
         if(u.permission)
         {
           
          return u
          
         }
         
     })
   } // contains only Alex and James

  },
};
</script>
