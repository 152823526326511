import store from "@/store";
export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'PieChartIcon',
    permission:store.state.auth.permissions.includes('dashboard-view')
    
  },
  {
    title: 'Standards',
    route: 'listing',
    icon: 'TargetIcon',
    permission:store.state.auth.permissions.includes('standard-list')
  },
  {
    title: 'Asset categories',
    route: 'assets',
    icon: 'GridIcon',
    permission:store.state.auth.permissions.includes('asset_category-list')
  },
  {
    title: 'Vulnerabilities',
    route: 'vulnerabilities',
    icon: 'FileIcon',
    permission:store.state.auth.permissions.includes('vulnerability-list')
  },
  {
    title: 'Threats',
    route: 'threat',
    icon: 'AlertTriangleIcon',
    permission:store.state.auth.permissions.includes('threat-list')
  },
  {
    title: 'Risk',
    route: 'risk',
    icon: 'ActivityIcon',
    permission:store.state.auth.permissions.includes('risk-list')
  },
  {
    title: 'Periodic Activities',
    route: 'periodicactivity',
    icon: 'RefreshCwIcon',
    permission:store.state.auth.permissions.includes('periodic_activity-list')
  },
  {
    title: 'TPR Questionnaires',
    route: 'tprquestions',
    icon: 'HelpCircleIcon',
    permission:store.state.auth.permissions.includes('tpr_questionnaire-list')
  },
  {
    title: 'Legal Register',
    route: 'legalregister',
    icon: 'PenToolIcon',
    permission:true,
  },
  {
    title: 'User Management',
    route: 'usermanagement',
    icon: 'UsersIcon',
    permission:store.state.auth.permissions.includes('user-list')
  },
  {
    title: 'Deleted Users',
    route: 'deleted-users',
    icon: 'UsersIcon',
    permission:store.state.auth.permissions.includes('user-list')
  },
  {
    title: 'Roles',
    route: 'roles',
    icon: 'UserCheckIcon',
    permission:store.state.auth.permissions.includes('role-list')
  },
  {
    title: 'Customers',
    route: 'customers',
    icon: 'UserIcon',
    permission:store.state.auth.permissions.includes('customer-list')
  },
  {
    title: "Policies",
    route: "policies",
    icon: "FileTextIcon",
    permission:true,
  },
  // {
  //   title: 'Permissions',
  //   route: 'permission',
  //   icon: 'FileIcon'
  // },
]
